const navRoutes = {
    'liverpool-property-maintenance': 'liverpool-property-maintenance',
    'bootle-property-maintenance': 'bootle-property-maintenance',
    'crosby-property-maintenance': 'crosby-property-maintenance',
    'formby-property-maintenance': 'formby-property-maintenance',
    'kirkby-property-maintenance': 'kirkby-property-maintenance',
    'maghull-property-maintenance': 'maghull-property-maintenance',
    'prescot-property-maintenance': 'prescot-property-maintenance',
    'southport-property-maintenance': 'southport-property-maintenance',
    'wallasey-property-maintenance': 'wallasey-property-maintenance',
    'wirral-property-maintenance': 'wirral-property-maintenance',
    'birkenhead-property-maintenance': 'birkenhead-property-maintenance',
    'huyton-property-maintenance': 'huyton-property-maintenance',
    'knowsley-property-maintenance': 'knowsley-property-maintenance',
    'garston-property-maintenance': 'garston-property-maintenance',
    'halewood-property-maintenance': 'halewood-property-maintenance',
    'childwall-property-maintenance': 'childwall-property-maintenance',
    'allerton-property-maintenance': 'allerton-property-maintenance',
    'aigburth-property-maintenance': 'aigburth-property-maintenance',
    'everton-property-maintenance': 'everton-property-maintenance',
    'anfield-property-maintenance': 'anfield-property-maintenance',
    'toxteth-property-maintenance': 'toxteth-property-maintenance',
    'kensington-property-maintenance': 'kensington-property-maintenance',
    'wavertree-property-maintenance': 'wavertree-property-maintenance',
    'sefton-property-maintenance': 'sefton-property-maintenance',
    'speke-property-maintenance': 'speke-property-maintenance',
    'norris-green-property-maintenance': 'norris-green-property-maintenance',
    'west-derby-property-maintenance': 'west-derby-property-maintenance',
    'cressington-property-maintenance': 'cressington-property-maintenance',
    'belle-vale-property-maintenance': 'belle-vale-property-maintenance',
    'walton-property-maintenance': 'walton-property-maintenance',
    'fairfield-property-maintenance': 'fairfield-property-maintenance',
    'prenton-property-maintenance': 'prenton-property-maintenance',
    'west-kirby-property-maintenance': 'west-kirby-property-maintenance',
    'moreton-property-maintenance': 'moreton-property-maintenance',
    'upton-property-maintenance': 'upton-property-maintenance',
    'new-brighton-property-maintenance': 'new-brighton-property-maintenance',
    'rock-ferry-property-maintenance': 'rock-ferry-property-maintenance',
    'bromborough-property-maintenance': 'bromborough-property-maintenance',
    'port-sunlight-property-maintenance': 'port-sunlight-property-maintenance',
    'heswall-property-maintenance': 'heswall-property-maintenance',
    'netherton-property-maintenance': 'netherton-property-maintenance',
    'aigburth-handyman': 'aigburth-handyman',
    'liverpool-city-centre-handyman': 'liverpool-city-centre-handyman',
    'birkenhead-handyman': 'birkenhead-handyman',
    'sefton-handyman': 'sefton-handyman'
};

module.exports = navRoutes;
